import AuthLayout from "../layouts/AuthLayout";
import VisitorLayout from "../layouts/VisitorLayout";
import Signup from "../pages/auth/Signup";
import DestinationDetails from "../pages/visitor/DestinationDetails";
import Discovery from "../pages/visitor/Discovery";
import LandingPage from "../pages/visitor/LandingPage";

/**
 * *Import component and create a route by define a path.
 *
 */
const AuthLayoutFunc = (comp) => {
  return <AuthLayout>{comp}</AuthLayout>;
};
const VisitorLayoutFunc = (comp) => {
  return <VisitorLayout>{comp}</VisitorLayout>;
};
const routes = [
  {
    path: "/",
    element: VisitorLayoutFunc(<LandingPage />),
  },
  {
    path: "/discovery",
    element: VisitorLayoutFunc(<Discovery />),
  },
  {
    path: "/signup",
    element: AuthLayoutFunc(<Signup />),
  },
  {
    path: "/destination-details/*",
    element: VisitorLayoutFunc(<DestinationDetails />),
  },
];

export default routes;
