import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const DiscoveryBannerSection = () => {
  const options = {
    items: 2,
    dots: false,
    nav: true,
    loop: true,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 5,
      },
    },
  };
  const staticCaroselArr = [
    {
      name: "Adventure",
      img: "/images/discovery/zip-line-1.png",
    },
    {
      name: "Forest",
      img: "/images/discovery/zip-line-2.png",
    },
    {
      name: "Mountain",
      img: "/images/discovery/zip-line-3.png",
    },
    {
      name: "Beach",
      img: "/images/discovery/zip-line-4.png",
    },
    {
      name: "Desert",
      img: "/images/discovery/zip-line-5.png",
    },
    {
      name: "City",
      img: "/images/discovery/zip-line-6.png",
    },
  ];
  return (
    <div className={`flex flex-col justify-between gap-10 md:py-[6%]`}>
      <div className={`h-full flex flex-col gap-5 mt-3`}>
        <div
          className={`bg-white flex justify-between lg:w-1/2 w-[90%] m-auto p-2 rounded-lg`}>
          <input
            className={`px-4 w-[75%]`}
            placeholder="Type Destination"
          />
          <div>
            <button
              className={`flex bg-[#FFCC57] px-8 py-4 items-center justify-center rounded-lg`}>
              <img
                alt="search"
                src="/images/Icons/search.png"
              />
              Search
            </button>
          </div>
        </div>
        <div>
          <div className={`flex items-center gap-4 justify-center`}>
            <p
              className={`text-[15px] sm:text-[30px] lg:text-[60px] text-white font-[PTSansBold] uppercase`}>
              Organize your
            </p>
            <p
              className={`text-[15px] sm:text-[30px] lg:text-[60px] font-[PTSansBold] uppercase text-stroke`}>
              adventure
            </p>
          </div>
          <div className={``}>
            <p
              className={`text-xs lg:text-base text-white font-[PTSans] text-center`}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua..
            </p>
          </div>
        </div>
        <div>
          <OwlCarousel
            className="owl-theme !w-2/3 m-auto"
            {...options}>
            {staticCaroselArr?.map((item, idx) => {
              return (
                <div>
                  <img
                    alt="destinationtypes"
                    src={item?.img}
                    className={`h-24 !w-24 m-auto`}
                  />
                  <p
                    className={`font-[PTSansBold] text-white text-xl text-center`}>
                    {item?.name}
                  </p>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default DiscoveryBannerSection;
