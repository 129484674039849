import React from "react";
import getBaseUrl from "../../../common/getBaseUrl";

const DestinationSingleCard = ({ gotoDestinationDetails, item }) => {
  const [img, setImg] = React.useState("/images/destination.png");
  React.useEffect(() => {
    fetch(`${getBaseUrl()}/image?query=` + item?.name)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setImg(data.url);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(img);
  });

  return (
    <div
      className={`bg-white rounded-xl relative overflow-hidden p-2`}
      onClick={() => gotoDestinationDetails(item)}>
      <div>
        <img
          key={Date.now()}
          src={`${img}`}
          alt="destination"
          className={`h-[250px] w-full object-cover rounded-[20px]`}
        />
      </div>
      <div className={`py-4`}>
        <p className={`font-[PTSansBold] text-3xl text-[#002755]`}>
          {item?.name}
        </p>
        <div className={`flex gap-2 mt-2`}>
          {item?.tags.map((tag) => {
            return (
              <p className={`px-2 bg-[#FFF5DD] text-sm font-[PTSans]`}>{tag}</p>
            );
          })}
        </div>
        <p className={`text-sm font-[PTSans] mt-2`}>{item?.description}</p>
        <div className={`absolute top-8 right-4 bg-[#FFF5DD] rounded-3xl px-6`}>
          <p className={`font-[PTSansBold] text-xl text-[#002755]`}>
            {item?.days} Days
          </p>
        </div>
      </div>
    </div>
  );
};

export default DestinationSingleCard;
