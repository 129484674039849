import React from "react";
import DiscoveryBannerSection from "./DiscoveryBannerSection";
import Destinations from "./Destinations";

const Discovery = () => {
  return (
    <div>
      <DiscoveryBannerSection />
      <Destinations />
    </div>
  );
};

export default Discovery;
