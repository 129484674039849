import React from "react";
import "./LandingPage.css";
import BannerSection from "./BannerSection";
import PopularDestinations from "./PopularDestinations";
// import ExploreWorldSection from "./ExploreWorldSection";
// import ClientTestimonial from "./ClientTestimonial";

const LandingPage = () => {
  return (
    <>
      <BannerSection />
      <PopularDestinations />
      {/* <ExploreWorldSection /> */}
      {/* <ClientTestimonial /> */}
    </>
  );
};

export default LandingPage;
