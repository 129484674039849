import React from "react";
import DetailsPlannerSingleCard from "./DetailsPlannerSingleCard";

const DetailsPlanner = ({ itineraryObj }) => {
  console.log("itineraryObj", itineraryObj);
  const mapsUrl = `https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${itineraryObj.city}&t=&z=12&ie=UTF8&iwloc=B&output=embed`;
  return (
    <div className={`bg-white p-[6%] pt-0`}>
      <div>
        <div
          className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-6 py-[3%] container mx-auto `}>
          <div>
            <iframe
              title="map of city"
              className="rounded-xl sticky top-[100px;]"
              width="100%"
              height={500}
              src={mapsUrl}></iframe>
          </div>
          <div>
            {itineraryObj?.itineraryByDay?.map((itineraryByDay) => {
              return (
                <>
                  <DetailsPlannerSingleCard itineraryByDay={itineraryByDay} />
                  <br />
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPlanner;
