import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const VisitorHeader = () => {
  const navigate = useNavigate();
  const [openHam, setOpenHam] = useState(false);
  const gotoDiscovery = () => {
    setOpenHam(false);
    navigate("/discovery");
  };
  //   const gotoHome = () => {
  //     setOpenHam(false);
  //     navigate("/");
  //   };
  return (
    <div className={`bg-[#00000094]  top-0 fixed w-full z-10 backdrop-blur-sm`}>
      <div
        className={`flex justify-between container px-2 mx-auto items-center h-20 border-b-white border-b-[1px] `}>
        <div>
          <a href="/">
            <p className={`text-white text-[30px] font-[Urbanist] font-bold `}>
              ExpeditionAI
            </p>
          </a>
        </div>
        {/* <div
          className={`hidden lg:flex xl:w-1/4 lg:w-1/3 justify-between items-center`}>
          <p
            className={`text-lg text-white font-[PTSans] cursor-pointer ${
              window.location?.pathname === "/discovery" && "text-yellow-500"
            }`}
            onClick={gotoDiscovery}></p>
          <p
            className={`text-lg text-white font-[PTSans] cursor-pointer ${
              window.location?.pathname === "" && "text-yellow-500"
            }`}></p>
          <button
            className={`text-lg text-white border-white border-[1px] py-2 px-10 rounded-lg font-[PTSans]`}
            onClick={gotoDiscovery}>
            Discover
          </button>
        </div> */}
        <div className={`block hidden`}>
          <img
            alt="hamIcon"
            src="/images/Icons/menuOpen.png"
            onClick={() => setOpenHam(true)}
          />
          {openHam && (
            <div
              className={`h-screen  absolute top-0 right-0 bg-[#001B3B] transition-all duration-700 z-10 w-screen !fixed top-0 right-0`}>
              <div
                className={`flex justify-between  h-24 border-b-white border-b-[1px] items-center px-[2%] container m-auto block`}>
                <p
                  className={`text-white text-[32px] font-[Urbanist] font-bold`}>
                  TRAVEL PLANNER
                </p>
                <img
                  alt="hamIcon"
                  src="/images/Icons/menuClose.png"
                  onClick={() => setOpenHam(false)}
                />
              </div>
              <div
                className={`flex flex-col gap-10 items-center mt-10 ${
                  openHam ? "block" : "hidden"
                }`}>
                <p
                  className={`text-lg text-white font-bold font-[PTSans] cursor-pointer ${
                    window.location?.pathname === "/discovery" &&
                    "text-yellow-500"
                  }`}
                  onClick={gotoDiscovery}>
                  Discover
                </p>
                <p
                  className={`text-lg text-white font-bold font-[PTSans] cursor-pointer ${
                    window.location?.pathname === "" && "text-yellow-500"
                  }`}>
                  Login
                </p>
                <button
                  className={`text-lg text-white border-white border-[1px] py-2 px-10 rounded-lg font-[PTSans]`}>
                  Sign Up
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VisitorHeader;
