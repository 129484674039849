import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import DestinationSingleCard from "../Discovery/DestinationSingleCard";
import { constructSearchURL } from "../../../routing/routeConstructor";

const PopularDestinations = () => {
  const navigate = useNavigate();
  const staticDestinations = [
    {
      name: "Bali, Indonesia",
      days: 7,
      tags: [],
      description: "",
    },
    {
      name: "New York, NY",
      days: 3,
      tags: [],
      description: "",
    },
    {
      name: "Nassau, Bahamas",
      days: 4,
      tags: [],
      description: "",
    },
    {
      name: "Paris, France",
      days: 4,
      tags: [],
      description: "",
    },
    {
      name: "Bangkok, Thailand",
      days: 10,
      tags: [],
      description: "",
    },
    {
      name: "Sydney, Australia",
      days: 5,
      tags: [],
      description: "",
    },
  ];
  const gotoDestinationDetails = (destination) => {
    navigate(constructSearchURL(destination.name, destination.days));
  };
  const options = {
    items: 3,
    dots: false,
    nav: true,
    loop: true,
    margin: 20,
    center: true,
    mergeFit: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: true,
      },
      600: {
        items: 2,
        nav: false,
        dots: true,
      },
      1000: {
        items: 3,
      },
    },
  };
  // const gotoDiscovery = () => {
  //   navigate("/discovery");
  // };
  return (
    <div className={`bg-white px-[3%]`}>
      <div className={`container mx-auto flex flex-col gap-10 py-16`}>
        <div
          className={`flex justify-between ms:items-end flex-col md:flex-row items-center gap-5`}>
          <div>
            <p
              className={` font-[PTSansBold] text-xl sm:text-3xl lg:text-5xl flex items-center gap-4 py-[5%]`}>
              Popular destinations
            </p>
            <p className={`text-xs lg:text-base font-[PTSans]`}>
              Recommended places for you
            </p>
          </div>
          {/* <div className={`w-full sm:w-fit`}>
            <button
              onClick={gotoDiscovery}
              className={`flex bg-[#FFCC57] px-8 py-4 w-full items-center justify-center rounded-lg`}>
              <img
                alt="img"
                className={`pr-[5px]`}
                src="/images/Icons/search.png"
              />
              Discover all
            </button>
          </div> */}
        </div>
        <div className={`px-8`}>
          <OwlCarousel
            className="owl-carousel owl-theme destination-owl"
            {...options}>
            {staticDestinations.map((item) => {
              return (
                <DestinationSingleCard
                  item={item}
                  gotoDestinationDetails={gotoDestinationDetails}
                  key={item.name}
                />
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default PopularDestinations;
