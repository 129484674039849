import React from "react";

const DetailsPlannerSingleCard = ({ itineraryByDay }) => {
  console.log("itineraryByDay", itineraryByDay);
  return (
    <div className={`bg-[#F4F4F4] p-[2%] w-[90%] mx-auto rounded-xl relative`}>
      <p
        className={`text-[#002755] p-[1%] text-lg sm:text-2xl lg:text-4xl font-[PTSansBold]`}>
        {" "}
        Day: {itineraryByDay?.[0].day}
      </p>
      <div className={` gap-4 mt-4`}>
        {itineraryByDay?.map((destination) => {
          return (
            <div className=" bg-white items-center p-[2%] gap-4 lg mb-4">
              <div
                className={`text-[#002755] text-xl sm:text-xl lg:text-xl font-[PTSansBold]`}>
                <p>{destination.location}</p>
              </div>
              <div
                className={`text-[#002755] text-m sm:text-m lg:text-l font-[PTSansBold]`}>
                <p>{destination.description}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className={`absolute top-0 left-[-20px] h-full`}>
        <div className={`h-3 w-3 bg-yellow-400 rounded-full`}></div>
        <div className={`h-full m-auto bg-yellow-400 w-[2px]`}></div>
      </div>
    </div>
  );
};

export default DetailsPlannerSingleCard;
