import React from "react";
import { useNavigate } from "react-router-dom";
import DestinationSingleCard from "./DestinationSingleCard";

const Destinations = () => {
  const navigate = useNavigate();
  const staticDestinations = [
    {
      img: "/images/destination.png",
      name: "Bali Island",
      days: "7 Days",
      tags: ["lorem", "lorem", "lorem"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..",
    },
    {
      img: "/images/destination.png",
      name: "Bali Island",
      days: "7 Days",
      tags: ["lorem", "lorem", "lorem"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..",
    },
    {
      img: "/images/destination.png",
      name: "Bali Island",
      days: "7 Days",
      tags: ["lorem", "lorem", "lorem"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..",
    },
    {
      img: "/images/destination.png",
      name: "Bali Island",
      days: "7 Days",
      tags: ["lorem", "lorem", "lorem"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..",
    },
    {
      img: "/images/destination.png",
      name: "Bali Island",
      days: "7 Days",
      tags: ["lorem", "lorem", "lorem"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..",
    },
    {
      img: "/images/destination.png",
      name: "Bali Island",
      days: "7 Days",
      tags: ["lorem", "lorem", "lorem"],
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..",
    },
  ];
  const gotoDestinationDetails = () => {
    navigate("/destination-details");
  };
  return (
    <>
      <hr className={`container mx-auto`} />
      <div className={`bg-white`}>
        <div className={`container mx-auto p-[3%]`}>
          <div className={`flex items-center gap-4 justify-start`}>
            <p
              className={`text-[15px] sm:text-[30px] lg:text-[60px] font-[PTSansBold] uppercase`}>
              destinations
            </p>
          </div>
          <div className={``}>
            <p className={`text-xs lg:text-base font-[PTSans]`}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua..
            </p>
          </div>
        </div>

        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 py-[5%] mx-auto container px-2`}>
          {staticDestinations.map((item) => {
            return (
              <DestinationSingleCard
                item={item}
                gotoDestinationDetails={gotoDestinationDetails}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Destinations;
