const constructSearchURL = (city, days) => {
  let daysInt;
  try {
    daysInt = parseInt(days);
    if (city && Number.isInteger(daysInt) && daysInt > 0)
      return `/destination-details?location=${city}&days=${days}`;
  } catch (e) {
    console.error(e);
  }
};

export { constructSearchURL };
