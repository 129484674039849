import React from "react";
import VisitorHeader from "../components/header/VisitorHeader";
import VisitorFooter from "../components/footer/VisitorFooter";
const VisitorLayout = ({ children }) => {
  return (
    <div>
      <div
        style={{ backgroundImage: "url(/images/background2-scaled.jpg)" }}
        className={` absolute h-screen w-screen bg-no-repeat bg-center bg-cover visitor-main-page`}></div>
      <div
        style={{
          background:
            "linear-gradient(107.5deg, #001229 -1.09%, rgba(0, 51, 116, 0.5) 75.98%)",
        }}
        className={` w-screen absolute top-0`}>
        <VisitorHeader />
        <div
          className={` h-[80px]`}
          style={{
            background:
              "linear-gradient(107.5deg, #001229 -1.09%, rgba(0, 51, 116, 0.5) 75.98%)",
          }}></div>
        {children}
        <VisitorFooter />
      </div>
    </div>
  );
};

export default VisitorLayout;
