import React, { useEffect } from "react";
import DetailsHeadBanner from "./DetailsHeadBanner";
import DetailsPlanner from "./DetailsPlanner";
import { useSearchParams } from "react-router-dom";
import getBaseUrl from "../../../common/getBaseUrl";

const formatItineraryObj = (itineraryObj) => {
  let itineraryByDay = [];
  let dayGroup = [];
  let currDay = null;
  itineraryObj.itinerary.forEach((i) => {
    if (currDay === null) currDay = i.day;
    else if (currDay !== i.day) {
      currDay = i.day;
      itineraryByDay.push(dayGroup);
      dayGroup = [];
    }
    dayGroup.push(i);
  });
  itineraryByDay.push(dayGroup);

  itineraryObj.itineraryByDay = itineraryByDay;
  return itineraryObj;
}

const DestinationDetails = () => {
  let query = useSearchParams();
  const location = query[0].get("location");
  const days = query[0].get("days");
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [itineraryObj, setItineraryObj] = React.useState([]);

  console.log(location, days);

  useEffect(() => {
    fetch(`${getBaseUrl()}/travel_plan?city=${location}&days=${days}`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setItineraryObj(formatItineraryObj(data));
        setLoading(false);
      }).catch((err) => {
        console.error("Error fetching travel plan", err)
        setError(true);
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingView = (
    <div className="flex">
      <div className="bg-white flex justify-center flex-col items-center m-left-0 m-right-0 w-[100%] h-[80vh]">
        <h2 className="font-[PTSansBold] text-3xl sm:text-3xl lg:text-5xl">
          Loading...
        </h2>
        <br></br>
        <p className="font-[PTSans] text-xl sm:text-xl lg:text-2xl">
          This can take up to 30 seconds
        </p>
        <br></br>
        <img
          src="238.gif"
          alt="Loading animation"></img>
      </div>
    </div>
  );

  const errorView = (
    <div className="flex">
      <div className="bg-white flex justify-center flex-col items-center m-left-0 m-right-0 w-[100%] h-[80vh]">
        <h2 className="font-[PTSansBold] text-3xl sm:text-3xl lg:text-5xl">
          Loading...
        </h2>
        <br></br>
        <p className="font-[PTSans] text-xl sm:text-xl lg:text-2xl">
          There was an error creating the travel plan. Please try again...
        </p>
      </div>
    </div>
  )

  if (loading) return loadingView
  else if (error) return errorView
  else {
    return (
      <div>
      <DetailsHeadBanner itineraryObj={itineraryObj} />
      <DetailsPlanner itineraryObj={itineraryObj} />
    </div>
    )
  }
};

export default DestinationDetails;
