import React from "react";

const DetailsHeadBanner = ({ itineraryObj }) => {
  const { locationDescription, city } = itineraryObj;
  return (
    <div
      className={`flex justify-center flex-col items-center container px-[5%] py-[5%] m-auto`}>
      <div className={`flex items-center gap-4 mb-10`}>
        <img
          src="/images/detailsLogo.png"
          alt="logo"
        />
        <p
          className={`text-white font-[PTSansBold] text-xl sm:text-3xl lg:text-5xl flex items-center gap-4`}>
          {city}{" "}
          {/* <span>
            <img
              src="/images/Icons/editIcon.png"
              alt="editIcon"
            />
          </span> */}
        </p>
      </div>
      <div>
        <p className={`text-l lg:text-xl text-white font-[PTSans] text-center`}>
          {locationDescription}
        </p>
      </div>
    </div>
  );
};

export default DetailsHeadBanner;
