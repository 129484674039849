function getBaseUrl() {
  const { NODE_ENV } = process.env;
  let baseURL;

  switch (NODE_ENV) {
    case "production":
      baseURL = "https://server.expedition-ai.com";
      break;
    default:
      baseURL = "http://localhost:3003";
      break;
  }

  return baseURL;
}

export default getBaseUrl;
