import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BannerSection = () => {
  const navigate = useNavigate();
  const [city, setCity] = useState("");
  const [days, setDays] = useState("");
  const openSearchPage = () => {
    let daysInt = parseInt(days, 10);
    let cityFinal = city || 'Bali, Indonesia'
    if (!daysInt || !Number.isInteger(daysInt) || daysInt < 1 || daysInt > 7) {
      daysInt = 7
    }

    navigate(`/destination-details?location=${cityFinal}&days=${daysInt}`);
  };

  const updateInputValue = (e, setFn) => {
    setFn(e.target.value);
  };

  return (
    <div
      className={`flex gap-[10px] mt-5 flex-col items-center justify-center h-[70%]`}>
      {/* Destination search section */}
      {/* Journey discovery text */}
      <div className={`flex flex-col items-center leading-none mt-[50px]`}>
        <p
          className={`text-[6vh] xs:text-[90px] sm:text-[90px] md:text-[90px] lg:text-[110px] xl:text-[110px] text-center leading-1 text-white font-[PTSansBold] uppercase`}>
          Your Travel Guide To
        </p>
        <p
          className={`text-[6vh] xs:text-[90px] sm:text-[90px] md:text-[90px] lg:text-[110px] xl:text-[110px] text-center leading-1	font-[PTSansBold] uppercase text-stroke`}>
          Discovery
        </p>
      </div>
      <br></br>
      {/* Subtext */}
      <div className={`w-2/3`}>
        <p
          className={`text-[20px] xs:text-[35px] sm:text-[35px] md:text-[35px] lg:text-[30px] xl:text-[30px] text-white font-[PTSans] text-center`}>
          Specially created and curated Travel Plans.
        </p>
        <p
          className={`text-[20px] xs:text-[35px] sm:text-[35px] md:text-[35px] lg:text-[30px] xl:text-[30px] text-white font-[PTSans] text-center`}>
          All you need to do is show up and have fun.
        </p>
      </div>
      <br></br>
      {/* Input form */}
      <div
        className={`bg-white w-[90%] xl:w-1/2 lg:w-2/3 flex rounded-lg min-h-[112px] items-center justify-between px-9 flex-wrap sm:flex-nowrap py-4`}>
        <div className={`flex gap-4 `}>
          <img
            alt="img"
            className={`h-8 w-8`}
            src="/images/Icons/locationIcon.png"
          />
          <div>
            <input
              className={`font-[PTSans] placeholder-gray-400 text-2xl	 text-gray-600 w-full`}
              onChange={(e) => updateInputValue(e, setCity)}
              value={city}
              type="text"
              placeholder="Bali, Indonesia"
            />
            <p className={`font-[PTSansBold] text-base text-[#002755]`}>
              Where are we going?
            </p>
          </div>
        </div>
        <div className={`md:border-r-2 border-black md:pr-[4%] h-1/2`}></div>
        <div className={`flex gap-4 md:pl-[4%]`}>
          <img
            alt="img"
            className={`h-7 w-7`}
            src="/images/Icons/calenderIcon.png"
          />
          <div>
            <input
              className={`font-[PTSans] placeholder-gray-400 text-2xl text-gray-600 w-[15%]`}
              placeholder="7"
              maxLength={2}
              onChange={(e) => updateInputValue(e, setDays)}
              value={days}
            />
            <span
              className={`font-[PTSans] placeholder-gray-400 text-2xl text-gray-600`}>
              days
            </span>
            <p className={`font-[PTSansBold] text-base text-[#002755]`}>
              For how long?
            </p>
          </div>
        </div>
        <div
          className={`w-full sm:w-fit`}
          onClick={() => {
            console.log("clicked");
            openSearchPage();
          }}>
          <button
            className={`flex bg-[#FFCC57] px-8 py-4 w-full items-center justify-center rounded-lg p-5`}>
            <img
              alt="img"
              className={`pr-[5px]`}
              src="/images/Icons/search.png"
            />
            Search
          </button>
        </div>
      </div>
      <br></br>
    </div>
  );
};

export default BannerSection;
